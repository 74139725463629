<template>
  <div id="mainMenu">
    <SideMenuComponent></SideMenuComponent>

    <div id="subSideMenu" v-if="showSubSideMenu">
      <!-- SubSide Title -->
      <div class="title">
        {{ selectedSection }}
      </div>

      <!-- Analytics Section. -->
      <div v-if="selectedSection == 'Settings'">
        <!-- <router-link to="/settings">
          <div class="menuItem" :class="{ selectedItem: selectedTab == 'General' }">General</div>
        </router-link> -->

        <router-link to="/settings/working-hours">
          <div index="ChartList" class="menuItem" :class="{ selectedItem: selectedTab == 'WorkihgHours' }"><i class="fa-regular fa-clock" /> &nbsp;Working Hours</div>
        </router-link>

        <router-link to="/settings/messaging-channels">
          <div index="MessagingChannels" class="menuItem" :class="{ selectedItem: selectedTab == 'MessagingChannels' }"><i class="fa-solid fa-inbox-full" /> &nbsp;Inboxes</div>
        </router-link>

        <router-link to="/settings/custom-reply">
          <div index="CannedResponse" class="menuItem" :class="{ selectedItem: selectedTab == 'CannedResponse' }"><i class="fa-regular fa-comments" /> &nbsp;Canned Responses</div>
        </router-link>

        <template>
          <router-link to="/settings/bot-templates">
            <div index="BotTemplates" class="menuItem" :class="{ selectedItem: selectedTab == 'BotTemplates' }"><i class="fa-regular fa-robot" /> &nbsp;Bot Templates</div>
          </router-link>

          <router-link to="/settings/tags">
            <div index="BotTemplates" class="menuItem" :class="{ selectedItem: selectedTab == 'Tags' }"><i class="fa-regular fa-tag" /> &nbsp;Tags</div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./subSideMenu.scss"></style>

<script>
import SideMenuComponent from './../sideMenu/sideMenu';

export default {
  name: 'app',
  data() {
    return {
      showSubSideMenu: false,
      selectedSection: '',
      selectedTab: 'General'
    };
  },
  components: {
    SideMenuComponent
  },
  computed: {},
  methods: {
    handleRouteChange() {
      // Selected Section To Show In Title.
      this.selectedSection = this.getCurrentMenuSection();

      // Calculate Selected Sub Menu.
      this.showSubSideMenu = false;
      if (this.selectedSection == 'Settings') {
        this.showSubSideMenu = true;
      }

      this.selectedTab = '';
      if (this.showSubSideMenu) {
        // Settings Section

        // if (this.$route.path.startsWith('/settings/inbox-settings')) {
        //   this.selectedTab = 'General';
        // } else
        if (this.$route.path.startsWith('/settings/custom-reply')) {
          this.selectedTab = 'CannedResponse';
        } else if (this.$route.path.startsWith('/settings/working-hours')) {
          this.selectedTab = 'WorkihgHours';
        } else if (this.$route.path.startsWith('/settings/messaging-channels')) {
          this.selectedTab = 'MessagingChannels';
        } else if (this.$route.path.startsWith('/settings/bot-templates')) {
          this.selectedTab = 'BotTemplates';
        } else if (this.$route.path.startsWith('/settings/tags')) {
          this.selectedTab = 'Tags';
        }
      }
    }
  },

  //Route Change handling
  watch: {
    $route() {
      this.handleRouteChange();
    }
  },

  created() {
    this.handleRouteChange();
  }
};
</script>
