import * as Sentry from '@sentry/vue';
import moment from 'moment';
const notificationMusic = require('@/assets/audio/new-message.mp3');

export default {
  methods: {
    generateUUID() {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
    },

    async validateForm(form) {
      return new Promise((resolve) => {
        form.validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    sleep(milliSeconds) {
      return new Promise((resolve) => setTimeout(resolve, milliSeconds));
    },

    reportError(error) {
      if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
        Sentry.captureException(error);
      } else {
        console.error(error);
      }
    },

    getCurrentMenuSection() {
      if (location.pathname.startsWith('/template')) {
        return 'Templates';
      } else if (location.pathname.startsWith('/customer')) {
        return 'Customers';
      } else if (location.pathname.startsWith('/settings')) {
        return 'Settings';
      } else {
        return 'Inbox';
      }
    },

    //Get descriptive time difference
    getReleventTime(date, skipAgoSuffix) {
      try {
        parseInt(date);
        if (!date) {
          return 'N/A';
        }
        return moment(date, 'x').fromNow(skipAgoSuffix);
      } catch (e) {
        console.error(e);
        return 'N/A';
      }
    },

    //#region -------------- Start: Toast --------------

    //Show error toast message
    errorToast(errorMsg, duration) {
      this.$message.error({
        message: errorMsg,
        showClose: true,
        duration: duration ? duration : 5000
      });
    },

    successToast(message, duration) {
      this.$message({
        type: 'success',
        message: message,
        showClose: true,
        duration: duration ? duration : 5000
      });
    },

    warningToast(message, duration) {
      this.$message({
        type: 'warning',
        message: message,
        showClose: true,
        duration: duration ? duration : 5000
      });
    },

    infoToast(message, duration) {
      this.$message({
        message: message,
        showClose: true,
        duration: duration ? duration : 5000
      });
    },

    getCountryCodeList() {
      let countryList = [
        ['Afghanistan', 'af', '93'],
        ['Albania', 'al', '355'],
        ['Algeria', 'dz', '213'],
        ['American Samoa', 'as', '1684'],
        ['Andorra', 'ad', '376'],
        ['Angola', 'ao', '244'],
        ['Anguilla', 'ai', '1264'],
        ['Antigua and Barbuda', 'ag', '1268'],
        ['Argentina', 'ar', '54'],
        ['Armenia', 'am', '374'],
        ['Aruba', 'aw', '297'],
        ['Australia', 'au', '61', 0],
        ['Austria', 'at', '43'],
        ['Azerbaijan', 'az', '994'],
        ['Bahamas', 'bs', '1242'],
        ['Bahrain', 'bh', '973'],
        ['Bangladesh', 'bd', '880'],
        ['Barbados', 'bb', '1246'],
        ['Belarus', 'by', '375'],
        ['Belgium', 'be', '32'],
        ['Belize', 'bz', '501'],
        ['Benin', 'bj', '229'],
        ['Bermuda', 'bm', '1441'],
        ['Bhutan', 'bt', '975'],
        ['Bolivia', 'bo', '591'],
        ['Bosnia and Herzegovina', 'ba', '387'],
        ['Botswana', 'bw', '267'],
        ['Brazil', 'br', '55'],
        ['British Indian Ocean Territory', 'io', '246'],
        ['British Virgin Islands', 'vg', '1284'],
        ['Brunei', 'bn', '673'],
        ['Bulgaria', 'bg', '359'],
        ['Burkina Faso', 'bf', '226'],
        ['Burundi', 'bi', '257'],
        ['Cambodia', 'kh', '855'],
        ['Cameroon', 'cm', '237'],
        ['Canada', 'ca', '1'],
        ['Cape Verde', 'cv', '238'],
        ['Caribbean Netherlands', 'bq', '599', 1],
        ['Cayman Islands', 'ky', '1345'],
        ['Central African Republic', 'cf', '236'],
        ['Chad (Tchad)', 'td', '235'],
        ['Chile', 'cl', '56'],
        ['China (中国)', 'cn', '86'],
        ['Christmas Island', 'cx', '61', 2],
        ['Cocos (Keeling) Islands', 'cc', '61', 1],
        ['Colombia', 'co', '57'],
        ['Comoros', 'km', '269'],
        ['Congo (DRC)', 'cd', '243'],
        ['Congo (Republic)', 'cg', '242'],
        ['Cook Islands', 'ck', '682'],
        ['Costa Rica', 'cr', '506'],
        ['Côte dIvoire', 'ci', '225'],
        ['Croatia (Hrvatska)', 'hr', '385'],
        ['Cuba', 'cu', '53'],
        ['Curaçao', 'cw', '599', 0],
        ['Cyprus', 'cy', '357'],
        ['Czech Republic', 'cz', '420'],
        ['Denmark (Danmark)', 'dk', '45'],
        ['Djibouti', 'dj', '253'],
        ['Dominica', 'dm', '1767'],
        ['Dominican Republic', 'do', '1'],
        ['Ecuador', 'ec', '593'],
        ['Egypt', 'eg', '20'],
        ['El Salvador', 'sv', '503'],
        ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240'],
        ['Eritrea', 'er', '291'],
        ['Estonia (Eesti)', 'ee', '372'],
        ['Ethiopia', 'et', '251'],
        ['Falkland Islands (Islas Malvinas)', 'fk', '500'],
        ['Faroe Islands (Føroyar)', 'fo', '298'],
        ['Fiji', 'fj', '679'],
        ['Finland (Suomi)', 'fi', '358', 0],
        ['France', 'fr', '33'],
        ['French Guiana (Guyane française)', 'gf', '594'],
        ['French Polynesia (Polynésie française)', 'pf', '689'],
        ['Gabon', 'ga', '241'],
        ['Gambia', 'gm', '220'],
        ['Georgia (საქართველო)', 'ge', '995'],
        ['Germany (Deutschland)', 'de', '49'],
        ['Ghana (Gaana)', 'gh', '233'],
        ['Gibraltar', 'gi', '350'],
        ['Greece (Ελλάδα)', 'gr', '30'],
        ['Greenland (Kalaallit Nunaat)', 'gl', '299'],
        ['Grenada', 'gd', '1473'],
        ['Guadeloupe', 'gp', '590', 0],
        ['Guam', 'gu', '1671'],
        ['Guatemala', 'gt', '502'],
        ['Guernsey', 'gg', '44', 1],
        ['Guinea (Guinée)', 'gn', '224'],
        ['Guinea-Bissau (Guiné Bissau)', 'gw', '245'],
        ['Guyana', 'gy', '592'],
        ['Haiti', 'ht', '509'],
        ['Honduras', 'hn', '504'],
        ['Hong Kong (香港)', 'hk', '852'],
        ['Hungary (Magyarország)', 'hu', '36'],
        ['Iceland (Ísland)', 'is', '354'],
        ['India (भारत)', 'in', '91'],
        ['Indonesia', 'id', '62'],
        ['Iran', 'ir', '98'],
        ['Iraq', 'iq', '964'],
        ['Ireland', 'ie', '353'],
        ['Isle of Man', 'im', '44', 2],
        ['Israel', 'il', '972'],
        ['Italy (Italia)', 'it', '39', 0],
        ['Jamaica', 'jm', '1876'],
        ['Japan (日本)', 'jp', '81'],
        ['Jersey', 'je', '44', 3],
        ['Jordan', 'jo', '962'],
        ['Kazakhstan (Казахстан)', 'kz', '7', 1],
        ['Kenya', 'ke', '254'],
        ['Kiribati', 'ki', '686'],
        ['Kosovo', 'xk', '383'],
        ['Kuwait', 'kw', '965'],
        ['Kyrgyzstan (Кыргызстан)', 'kg', '996'],
        ['Laos (ລາວ)', 'la', '856'],
        ['Latvia (Latvija)', 'lv', '371'],
        ['Lebanon', 'lb', '961'],
        ['Lesotho', 'ls', '266'],
        ['Liberia', 'lr', '231'],
        ['Libya', 'ly', '218'],
        ['Liechtenstein', 'li', '423'],
        ['Lithuania', 'lt', '370'],
        ['Luxembourg', 'lu', '352'],
        ['Macau', 'mo', '853'],
        ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
        ['Madagascar (Madagasikara)', 'mg', '261'],
        ['Malawi', 'mw', '265'],
        ['Malaysia', 'my', '60'],
        ['Maldives', 'mv', '960'],
        ['Mali', 'ml', '223'],
        ['Malta', 'mt', '356'],
        ['Marshall Islands', 'mh', '692'],
        ['Martinique', 'mq', '596'],
        ['Mauritania', 'mr', '222'],
        ['Mauritius (Moris)', 'mu', '230'],
        ['Mayotte', 'yt', '262', 1],
        ['Mexico (México)', 'mx', '52'],
        ['Micronesia', 'fm', '691'],
        ['Moldova (Republica Moldova)', 'md', '373'],
        ['Monaco', 'mc', '377'],
        ['Mongolia (Монгол)', 'mn', '976'],
        ['Montenegro (Crna Gora)', 'me', '382'],
        ['Montserrat', 'ms', '1664'],
        ['Morocco', 'ma', '212', 0],
        ['Mozambique (Moçambique)', 'mz', '258'],
        ['Myanmar (Burma)', 'mm', '95'],
        ['Namibia (Namibië)', 'na', '264'],
        ['Nauru', 'nr', '674'],
        ['Nepal', 'np', '977'],
        ['Netherlands (Nederland)', 'nl', '31'],
        ['New Caledonia (Nouvelle-Calédonie)', 'nc', '687'],
        ['New Zealand', 'nz', '64'],
        ['Nicaragua', 'ni', '505'],
        ['Niger (Nijar)', 'ne', '227'],
        ['Nigeria', 'ng', '234'],
        ['Niue', 'nu', '683'],
        ['Norfolk Island', 'nf', '672'],
        ['North Korea', 'kp', '850'],
        ['Northern Mariana Islands', 'mp', '1670'],
        ['Norway (Norge)', 'no', '47', 0],
        ['Oman', 'om', '968'],
        ['Pakistan', 'pk', '92'],
        ['Palau', 'pw', '680'],
        ['Palestine', 'ps', '970'],
        ['Panama (Panamá)', 'pa', '507'],
        ['Papua New Guinea', 'pg', '675'],
        ['Paraguay', 'py', '595'],
        ['Peru (Perú)', 'pe', '51'],
        ['Philippines', 'ph', '63'],
        ['Poland (Polska)', 'pl', '48'],
        ['Portugal', 'pt', '351'],
        ['Puerto Rico', 'pr', '1', 3, ['787', '939']],
        ['Qatar', 'qa', '974'],
        ['Réunion (La Réunion)', 're', '262', 0],
        ['Romania (România)', 'ro', '40'],
        ['Russia (Россия)', 'ru', '7', 0],
        ['Rwanda', 'rw', '250'],
        ['Saint Barthélemy', 'bl', '590', 1],
        ['Saint Helena', 'sh', '290'],
        ['Saint Kitts and Nevis', 'kn', '1869'],
        ['Saint Lucia', 'lc', '1758'],
        ['Saint Martin (Saint-Martin (partie française))', 'mf', '590', 2],
        ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508'],
        ['Saint Vincent and the Grenadines', 'vc', '1784'],
        ['Samoa', 'ws', '685'],
        ['San Marino', 'sm', '378'],
        ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239'],
        ['Saudi Arabia', 'sa', '966'],
        ['Senegal', 'sn', '221'],
        ['Serbia', 'rs', '381'],
        ['Seychelles', 'sc', '248'],
        ['Sierra Leone', 'sl', '232'],
        ['Singapore', 'sg', '65'],
        ['Sint Maarten', 'sx', '1721'],
        ['Slovakia (Slovensko)', 'sk', '421'],
        ['Slovenia (Slovenija)', 'si', '386'],
        ['Solomon Islands', 'sb', '677'],
        ['Somalia (Soomaaliya)', 'so', '252'],
        ['South Africa', 'za', '27'],
        ['South Korea (대한민국)', 'kr', '82'],
        ['South Sudan', 'ss', '211'],
        ['Spain (España)', 'es', '34'],
        ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94'],
        ['Sudan', 'sd', '249'],
        ['Suriname', 'sr', '597'],
        ['Svalbard and Jan Mayen', 'sj', '47', 1],
        ['Swaziland', 'sz', '268'],
        ['Sweden (Sverige)', 'se', '46'],
        ['Switzerland (Schweiz)', 'ch', '41'],
        ['Syria', 'sy', '963'],
        ['Taiwan (台灣)', 'tw', '886'],
        ['Tajikistan', 'tj', '992'],
        ['Tanzania', 'tz', '255'],
        ['Thailand (ไทย)', 'th', '66'],
        ['Timor-Leste', 'tl', '670'],
        ['Togo', 'tg', '228'],
        ['Tokelau', 'tk', '690'],
        ['Tonga', 'to', '676'],
        ['Trinidad and Tobago', 'tt', '1868'],
        ['Tunisia', 'tn', '216'],
        ['Turkey (Türkiye)', 'tr', '90'],
        ['Turkmenistan', 'tm', '993'],
        ['Turks and Caicos Islands', 'tc', '1649'],
        ['Tuvalu', 'tv', '688'],
        ['U.S. Virgin Islands', 'vi', '1340'],
        ['Uganda', 'ug', '256'],
        ['Ukraine', 'ua', '380'],
        ['United Arab Emirates', 'ae', '971'],
        ['United Kingdom', 'gb', '44', 0],
        ['United States', 'us', '1', 0],
        ['Uruguay', 'uy', '598'],
        ['Uzbekistan', 'uz', '998'],
        ['Vanuatu', 'vu', '678'],
        ['Vatican City', 'va', '39', 1],
        ['Venezuela', 've', '58'],
        ['Vietnam (Việt Nam)', 'vn', '84'],
        ['Wallis and Futuna (Wallis-et-Futuna)', 'wf', '681'],
        ['Western Sahara', 'eh', '212', 1],
        ['Yemen', 'ye', '967'],
        ['Zambia', 'zm', '260'],
        ['Zimbabwe', 'zw', '263'],
        ['Åland Islands', 'ax', '358', 1]
      ];
      let countryKeyValArry = [];
      let countriesByCode = {};
      for (let i = 0; i < countryList.length; i++) {
        let obj = {
          countryName: countryList[i][0],
          countryCode: countryList[i][1],
          phoneCode: countryList[i][2]
        };
        countryKeyValArry.push(obj);
        countriesByCode[obj.countryCode] = obj;
      }
      return countryKeyValArry;
    },

    playNewNotificationSound() {
      try {
        let elementId = 'notification-music-elem';
        let audio = document.getElementById(elementId);

        if (!audio) {
          audio = document.createElement('AUDIO');
          audio.setAttribute('id', elementId);
          document.body.appendChild(audio);
          audio.src = notificationMusic;
        }
        audio.play();
      } catch (err) {
        console.error('Sound Play Failed', err);
      }
    },

    getRandomColor() {
      var letters = 'BCEDF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
      }
      return color;
    },

    getAllowedWhatsAppImageTypes() {
      return 'image/png, image/jpeg, image/jpg, image/webp';
    },

    getAllowedWhatsAppVideoTypes() {
      return 'video/mp4';
    },

    getAllowedWhatsAppDocumentTypes() {
      return 'application/pdf';
    },

    getChannelDisplayText(channel) {
      if (channel == 'whatsApp') return 'WhatsApp';
      else if (channel == 'sms') return 'SMS';
      else if (channel == 'email') return 'Email';
      else if (channel == 'instagram') return 'Instagram';
      else if (channel == 'facebook_messenger') return 'Facebook Messenger';
    },

    getDisplayTime(date) {
      if (!date) {
        return `N/A`;
      }
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY hh:mm A');
    }

    //#endregion -------------- Start: Toast --------------
  }
};
