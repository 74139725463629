const axios = require('axios');
const { chatServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.getToken = function () {
  return axios.get(chatServiceUrl + '/auth/token', {
    headers: buildAuthHeaders()
  });
};

exports.stagingLogin = function (params) {
  return axios.post(chatServiceUrl + '/auth/staging-login', params, {
    headers: buildAuthHeaders()
  });
};
