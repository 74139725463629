import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  routes: [
    // Chat Home Page
    {
      path: '/',
      name: 'Inbox',
      component: Vue.component('mainChatPage', () => import('./pages/chatMainPage/chatMainPage'))
    },

    // Customers List
    {
      path: '/customers',
      name: 'CustomerList',
      component: Vue.component('CustomerList', () => import('./pages/customers/list/customerList')),
      meta: {
        title: 'Customers'
      }
    },

    // Template - Create
    {
      path: '/templates',
      name: 'TemplateList',
      component: Vue.component('TemplateList', () => import('./pages/templates/list/templateList')),
      meta: {
        title: 'Templates'
      }
    },
    // Template - Create
    {
      path: '/templates/create',
      name: 'CreateTemplate',
      component: Vue.component('CreateTemplate', () => import('./pages/templates/create/createTemplate')),
      meta: {
        title: 'Create Template'
      }
    },

    // // Automation - Inbox Settings
    // {
    //   path: '/settings',
    //   name: 'InboxAutomationSettingsComponent',
    //   component: Vue.component('InboxAutomationSettingsComponent', () => import('./pages/automation/inboxAutomationSettings/inboxAutomationSettings')),
    //   meta: {
    //     title: 'Inbox Settings'
    //   }
    // },

    // Settings - Custom Reply List
    {
      path: '/settings/custom-reply',
      name: 'RustomReplyListComponent',
      component: Vue.component('RustomReplyListComponent', () => import('./pages/automation/customReplyList/customReplyList')),
      meta: {
        title: 'Custom Reply'
      }
    },

    // Settings - Working Hours
    {
      path: '/settings/working-hours',
      name: 'WorkingHoursComponent',
      component: Vue.component('WorkingHoursComponent', () => import('./pages/automation/workingHours/workingHours')),
      meta: {
        title: 'Working Hours'
      }
    },

    // Settings - Bot Templates
    {
      path: '/settings/bot-templates',
      name: 'BotTemplateListComponent',
      component: Vue.component('BotTemplateListComponent', () => import('./pages/settings/botTemplates/botTemplateList/botTemplateList')),
      meta: {
        title: 'Bot Templates'
      }
    },

    // Settings - Tags
    {
      path: '/settings/tags',
      name: 'TagListComponent',
      component: Vue.component('TagListComponent', () => import('./pages/settings/tagsList/tagsList')),
      meta: {
        title: 'Tags'
      }
    },

    // Settings - Messaging Channels
    {
      path: '/settings/messaging-channels',
      name: 'MessagingChannelsComponent',
      component: Vue.component('MessagingChannelsComponent', () => import('./pages/settings/messagingChannels/messagingChannels')),
      meta: {
        title: 'Messaging Channels'
      }
    },

    // Settings - Add Messaging Channels
    {
      path: '/settings/messaging-channels/add',
      name: 'AddMessagingChannel',
      component: Vue.component('AddMessagingChannel', () => import('./pages/settings/messagingChannels/addMessagingChannel/addMessagingChannel')),
      meta: {
        title: 'Messaging Channels'
      }
    },

    // Settings - Instagram oAuth Response
    {
      path: '/settings/messaging-channels/instagram/post-integration',
      name: 'InstagramIntegrationOauthHandler',
      component: Vue.component('InstagramIntegrationOauthHandler', () => import('./pages/settings/messagingChannels/oauth/instagramIntegrationOauthHandler')),
      meta: {
        title: 'Messaging Channels'
      }
    },

    // Settings - FB Messenger oAuth Response
    {
      path: '/settings/messaging-channels/fb-messenger/post-integration',
      name: 'FbMessengerIntegrationOauthHandler',
      component: Vue.component('FbMessengerIntegrationOauthHandler', () => import('./pages/settings/messagingChannels/oauth/fbMessengerIntegrationOauthHandler')),
      meta: {
        title: 'Messaging Channels'
      }
    },

    {
      path: '/staging-login',
      name: 'StagingLoginComponent',
      component: Vue.component('StagingLoginComponent', () => import('./pages/stagingLogin.vue')),
      meta: {
        title: 'Staging Login'
      }
    },

    // Page Not Found Component
    {
      path: '/404',
      name: '404',
      component: Vue.component('PageNotFound', () => import('./pages/others/404/404')),
      meta: {
        title: 'Page Not Found'
      }
    },

    {
      path: '*',
      name: 'UnknownPage',
      component: Vue.component('PageNotFound', () => import('./pages/others/404/404')),
      meta: {
        title: 'Page Not Found'
      }
    }
  ]
});
