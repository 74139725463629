// import SideMenuComponent from '@/components/sideMenu/sideMenu';
import SubSideMenuComponent from '@/components/subSideMenu/subSideMenu';
import authService from '@/services/authService';
// import SubSideMenuComponent from '@/components/subSideMenu/subSideMenu';
// import TopMenuComponent from '@/components/topMenu';
// import AccountSuspensionWarning from '@/components/accountSuspensionWarning';
// import authService from '@/services/auth';

export default {
  name: 'app',
  data() {
    return {
      showFullScreenLoader: false,
      loading: true
    };
  },
  components: {
    SubSideMenuComponent
    // TopMenuComponent,
    // AccountSuspensionWarning
  },
  computed: {},
  methods: {
    onLogout() {
      // authService.logout();
      this.onGoToPage('Login');
    },

    async syncToken() {
      try {
        let response = await authService.getToken();
        let tokenData = response.data.token;
        let rawToken = response.data.raw;

        this.$store.dispatch('setToken', tokenData);
        window.localStorage.setItem('grw-token', rawToken);

        if (window.clarity) {
          let lastUserId = window.localStorage.getItem('lastUserId');
          if (!lastUserId) {
            let sessionId = this.generateUUID();
            let userId = tokenData.companyName + '-' + tokenData.email;
            window.clarity('identify', userId, sessionId);
            window.clarity('set', 'Email', tokenData.email);
            window.clarity('set', 'Company Name', tokenData.companyName);
            window.clarity('set', 'Company ID', tokenData.companyId);
            window.clarity('set', 'User ID', tokenData.userId);
            window.clarity('set', 'First Name', tokenData.companyName);
            window.clarity('set', 'Last Name', tokenData.companyName);

            // Mark session identified.
            window.localStorage.setItem('lastUserId', sessionId);
          }
        }

        this.loading = false;

        // Identify user in clarity.
        // window.clarity('identify', 'response.data')
      } catch (err) {
        if (err.status == 401) {
          localStorage.removeItem('lastUserId');
          // Redirect To Login page.
          if (process.env.NODE_ENV === 'production') {
            window.location.href = 'https://app.growlytics.in/login';
          } else {
            window.location.href = 'http://localhost:8080/login';
          }
        }
        console.log('Load Failed', err);
      }
    }
  },

  async mounted() {
    if (window.location.pathname != '/staging-login') {
      this.syncToken();
    } else {
      this.loading = false;
    }
  },

  created: function () {}
};
