<template>
  <el-menu id="mainSideMenu" :style="{ 'background-image': selectedBackgroundColor }" v-if="$store.state.token">
    <div class="text-white absolute text-xs top-1 left-1 text-gray-500">V2</div>

    <!-- Analytics -->
    <img class="logo" src="@/assets/images/logo-square-light.png" />

    <!-- Menu Items -->
    <div class="menuItem" v-for="(item, index) in menuItems" :key="index" :class="{ selectedMenuItem: item.label == selectedSection }">
      <el-tooltip effect="dark" :content="item.label" placement="right">
        <router-link :to="item.url">
          <i :class="[item.icon]"></i>
        </router-link>
      </el-tooltip>
    </div>

    <!-- Profile At Footer -->
    <el-popover popper-class="profilePopover" placement="right" trigger="click">
      <div>
        <!-- <div class="companyItem">
          <router-link :to="{ name: 'SelectCompany' }">
            <div class="name">{{ $store.state.token }}</div>
            <div class="changeMessage">Change Organization</div>
          </router-link>
        </div> -->
        <div class="divider"></div>
        <div class="logoutItem" @click="onLogout">Logout</div>
      </div>

      <div class="profileMenu" slot="reference">{{ profileNameCapitals }}</div>
    </el-popover>
  </el-menu>
</template>

<style lang="scss" src="./sideMenu.scss"></style>

<script>
export default {
  name: 'app',
  data() {
    return {
      selectedSection: '',
      selectedBackgroundColor: null,
      profileNameCapitals: null,
      menuItems: [
        { icon: 'fa-solid fa-inbox', label: 'Inbox', url: '/' },
        { icon: 'fa-solid fa-users', label: 'Customers', url: '/customers' },
        { icon: 'fa-regular fa-book', label: 'Templates', url: '/templates' },
        // { icon: 'fa-solid fa-message-bot', label: 'Automations', url: '/automation/inbox-settings' }

        { icon: 'fa-solid fa-gear', label: 'Settings', url: '/settings/custom-reply' }
      ],
      randomBackground: [
        // `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23134047' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        `url("data:image/svg+xml,%3Csvg width='120' height='120' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0h2v20H9V0zm25.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm-20 20l1.732 1-10 17.32-1.732-1 10-17.32zM58.16 4.134l1 1.732-17.32 10-1-1.732 17.32-10zm-40 40l1 1.732-17.32 10-1-1.732 17.32-10zM80 9v2H60V9h20zM20 69v2H0v-2h20zm79.32-55l-1 1.732-17.32-10L82 4l17.32 10zm-80 80l-1 1.732-17.32-10L2 84l17.32 10zm96.546-75.84l-1.732 1-10-17.32 1.732-1 10 17.32zm-100 100l-1.732 1-10-17.32 1.732-1 10 17.32zM38.16 24.134l1 1.732-17.32 10-1-1.732 17.32-10zM60 29v2H40v-2h20zm19.32 5l-1 1.732-17.32-10L62 24l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM111 40h-2V20h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zM40 49v2H20v-2h20zm19.32 5l-1 1.732-17.32-10L42 44l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM91 60h-2V40h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM39.32 74l-1 1.732-17.32-10L22 64l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM71 80h-2V60h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM120 89v2h-20v-2h20zm-84.134 9.16l-1.732 1-10-17.32 1.732-1 10 17.32zM51 100h-2V80h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM100 109v2H80v-2h20zm19.32 5l-1 1.732-17.32-10 1-1.732 17.32 10zM31 120h-2v-20h2v20z' fill='%23134047' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E")`
        // `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23134047' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        // `url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%23134047' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E")`
      ]
    };
  },
  computed: {},
  methods: {
    setSelectedSection() {
      this.selectedSection = this.getCurrentMenuSection();
      console.log('Selected section is', this.selectedSection);
    },

    onLogout() {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = 'https://app.growlytics.in/login';
      } else {
        window.location.href = 'http://localhost:8080/login';
      }
    }
  },

  //Route Change handling
  watch: {
    $route() {
      this.setSelectedSection();
    }
  },
  created: function () {
    //select Background colors
    let backgroundIndex = Math.floor(Math.random() * this.randomBackground.length);
    this.selectedBackgroundColor = this.randomBackground[backgroundIndex];

    if (this.$store.state.token) {
      this.profileNameCapitals = this.$store.state.token.firstName[0].toUpperCase() + this.$store.state.token.lastName[0].toUpperCase();
    }

    // Admin Settings Visiblity.
    // if (this.$store.state.token && this.$store.state.token.role == 'admin') {
    //   this.menuItems.push({ icon: 'fa fa-cog', label: 'Settings', url: '/settings' });
    // }
  },

  mounted() {
    this.setSelectedSection();
  }
};
</script>
