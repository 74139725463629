let baseUrlNew = 'https://appi.growlytics.in/';

let marketingDashboardURL = 'https://app.growlytics.in';
let chatServiceUrl = 'https://chatapi1.growlytics.in';
let messageServiceUrl = 'https://chatapi-msg.growlytics.in';
let chatServiceApiServerlessUrl = 'https://chatapi-sls.growlytics.in';

if (process.env.NODE_ENV === 'production') {
  // Already set at top.
} else if (process.env.NODE_ENV === 'staging') {
  baseUrlNew = 'https://stagingappi.growlytics.in/';
  chatServiceUrl = 'https://staging-chatapi.growlytics.in';
  marketingDashboardURL = 'https://stagingapp.growlytics.in';
  chatServiceApiServerlessUrl = 'https://chatapi-sls-staging.growlytics.in';

  messageServiceUrl = 'https://chatapi-msg-staging.growlytics.in';
} else if (process.env.NODE_ENV === 'dev-staging') {
  baseUrlNew = 'https://stagingappi.growlytics.in/';
  marketingDashboardURL = 'https://devapp.growlytics.in';

  chatServiceUrl = 'https://staging-chatapi.growlytics.in';
  messageServiceUrl = 'https://chatapi-msg-dev.growlytics.in/6437';
} else {
  chatServiceUrl = 'https://chatapi.growlytics.in';
  // Local.
  baseUrlNew = 'http://localhost:5020/';
  chatServiceUrl = 'http://localhost:5939';
  marketingDashboardURL = 'http://localhost:8080';
  messageServiceUrl = 'http://localhost:6437';
  chatServiceApiServerlessUrl = 'http://localhost:18104';
}

// const allUsersList = [{ label: 'All users', allUsersList: [{ value: 'All users', label: 'All users' }] }, { label: 'Past Behavior Segments', allUsersList: [{ value: 'Frequent Buyers', label: 'Frequent Buyers' }, { value: 'All users', label: 'All users' }] }];
const options = [
  {
    options: [
      {
        value: 'All users',
        label: 'All users'
      }
    ]
  },
  {
    label: 'Past Behavior Segments',
    options: [
      {
        value: 'Frequent Buyers',
        label: 'Frequent Buyers'
      },
      {
        value: 'All Users',
        label: 'All users'
      }
    ]
  }
];
const timeEventList = [
  {
    value: 'Today',
    label: 'Today'
  },
  {
    value: 'In the  last 7 days',
    label: 'In the  last 7 days'
  },
  {
    value: 'In the  last 15 days',
    label: 'In the  last 15 days'
  },
  {
    value: 'In the  last 30 days',
    label: 'In the  last 30 days'
  },
  {
    value: 'In the  last 60 days',
    label: 'In the  last 60 days'
  },
  {
    value: 'This month',
    label: 'This month'
  },
  {
    value: 'Previous month',
    label: 'Previous month'
  },
  {
    value: 'Custom',
    label: 'Custom'
  },
  {
    value: 'Calendar',
    label: 'Calendar'
  }
];

const funnelStep = [
  {
    value: 'Added to Cart',
    label: 'Added to Cart'
  },
  {
    value: 'App Launched',
    label: 'App Launched'
  },
  {
    value: 'App Uninstalled',
    label: 'App Uninstalled'
  },
  {
    value: 'Category Viewed',
    label: 'Category Viewed'
  },
  {
    value: 'Charged',
    label: 'Charged'
  },
  {
    value: 'Notification Clicked',
    label: 'Notification Clicked'
  },
  {
    value: 'Notification Delivered',
    label: 'Notification Delivered'
  },
  {
    value: 'Notification Replied',
    label: 'Notification Replied'
  },
  {
    value: 'Notification Sent',
    label: 'Notification Sent'
  },
  {
    value: 'Notification Viewed',
    label: 'Notification Viewed '
  },
  {
    value: 'Payment Offer Applied',
    label: 'Payment Offer Applied'
  },
  {
    value: 'Product Viewed',
    label: 'Product Viewed'
  },
  {
    value: 'Product Impressions',
    label: 'Product Impressions'
  },
  {
    value: 'Reply Sent',
    label: 'Reply Sent'
  },
  {
    value: 'Searched',
    label: 'Searched'
  },
  {
    value: 'Support Call',
    label: 'Support Call'
  },
  {
    value: 'UTM Visited',
    label: 'UTM Visited'
  }
];

const funnelTimeList = [
  {
    value: '5 seconds',
    label: '5 seconds'
  },
  {
    value: '10 seconds',
    label: '10 seconds'
  },
  {
    value: '15 seconds',
    label: '15 seconds'
  },
  {
    value: '30 seconds',
    label: '30 seconds'
  },
  {
    value: '1 Minute',
    label: '1 Minute'
  },
  {
    value: '2 Minutes',
    label: '2 Minutes'
  },
  {
    value: '5 Minutes',
    label: '5 Minutes'
  },
  {
    value: '10 Minutes',
    label: '10 Minutes'
  },
  {
    value: '15 Minutes',
    label: '15 Minutes'
  },
  {
    value: '30 Minutes',
    label: '30 Minutes'
  },
  {
    value: '1 Hour',
    label: '1 Hour'
  },
  {
    value: '2 Hours',
    label: '2 Hours'
  },
  {
    value: '4 Hours',
    label: '4 Hours'
  },
  {
    value: '8 Hours',
    label: '8 Hours'
  },
  {
    value: '12 Hours',
    label: '12 Hours'
  },
  {
    value: '1 Day',
    label: '1 Day'
  },
  {
    value: '2 Days',
    label: '2 Days'
  },
  {
    value: '3 Days',
    label: '3 Days'
  },
  {
    value: '5 Days',
    label: '5 Days'
  },
  {
    value: '1 Week',
    label: '1 Week'
  },
  {
    value: '2 Weeks',
    label: '2 Weeks'
  },
  {
    value: '1 Month',
    label: '1 Month'
  },
  {
    value: '2 Months',
    label: '2 Months'
  },
  {
    value: '3 Months',
    label: '3 Months'
  }
];
module.exports = {
  baseUrlNew,
  chatServiceUrl,
  chatServiceApiServerlessUrl,
  marketingDashboardURL,
  messageServiceUrl,
  options,
  timeEventList,
  funnelStep,
  funnelTimeList,

  buildAuthHeaders: function () {
    let token = window.localStorage['grw-token'];
    return {
      'auth-token': token
    };
  }
};
