<template>
  <el-container id="app" style="height: 100vh">
    <div v-if="loading" class="w-full h-full" v-loading="true"></div>
    <el-container v-else>
      <!-- Main Page -->
      <el-main id="appDiv">
        <transition name="fade" v-if="!showFullScreenLoader">
          <div class="flex w-full overflow-hidden h-full">
            <!-- Side Bar Menu -->
            <div class="flex-none bg-white h-full">
              <SubSideMenuComponent></SubSideMenuComponent>
            </div>

            <!-- Router Menu. -->
            <div class="grow flex-grow overflow-y-scroll">
              <router-view v-bind:class="{ routerPagePadding: $store.state.showHeader }"></router-view>
            </div>
          </div>
        </transition>

        <!-- Settings Loader. -->
        <div v-loading="true" v-else style="width: 100%; height: 100vh; background: white">
          <div style="z-index: 9000; position: absolute; text-align: center; width: 100%; margin-top: 75vh">
            <img src="https://static.growlytics.in/dashboard-assets/assets/img/logo_hr.png" style="width: 200px" />
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppComponent from './appComponent';
export default AppComponent;
</script>

<style lang="scss" src="./main.scss"></style>
